<mat-toolbar>
    <a class="logo-container" routerLink="/">
        <img [src]="settings.get('branding.logo_dark')" alt="Site logo">
    </a>
    <form class="search-bar-container" (ngSubmit)="searchPanel.goToSearchPage()">
        <mat-icon svgIcon="search" class="search-bar-icon" [class.hidden]="searchPanel.searching || searchPanel.searchQuery.value" (click)="searchPanel.goToSearchPage()"></mat-icon>
        <label for="main-search" class="hidden" trans>Main site search</label>
        <input id="main-search" placeholder="Search Artists, Songs, Albums" [formControl]="searchPanel.searchQuery" autocomplete="off" trans-placeholder>

        <button type="button" class="no-style search-bar-icon" (click)="searchPanel.close()">
            <mat-icon svgIcon="close" [class.hidden]="searchPanel.searching || ! searchPanel.searchQuery.value"></mat-icon>
        </button>

        <loading-indicator [isVisible]="searchPanel.searching" class="small"></loading-indicator>
    </form>
    <span class="example-spacer"></span>
    <div class="auth-container">
        <div *ngIf="currentUser.isLoggedIn()" class="current-user">
            <a [routerLink]="profileLink" class="img-container">
                <img [src]="currentUser.get('avatar')" alt="user avatar">
            </a>
            <a class="name hover-underline" [routerLink]="profileLink">{{currentUser.get('display_name')}}</a>

            <button
                    mat-icon-button
                    [matMenuTriggerFor]="navSidebarMenu"
                    class="settings-button"
                    [matBadge]="(currentUser.model$ | async)?.unread_notifications_count"
                    matBadgeColor="accent"
                    matBadgeSize="small"
                    [matBadgeHidden]="(currentUser.model$ | async)?.unread_notifications_count < 1"
            >
                <mat-icon svgIcon="settings"></mat-icon>
            </button>

            <mat-menu #navSidebarMenu="matMenu">
               <nav-sidebar-user-menu></nav-sidebar-user-menu>
            </mat-menu>
        </div>

        <ng-container *ngIf="!currentUser.isLoggedIn()">
            <a routerLink="/login" class="loginbutton" trans>Login</a>
            <!-- <a routerLink="/register" *ngIf="!settings.get('registration.disable')" mat-button trans>Register</a> -->
        </ng-container>
    </div>
  </mat-toolbar>