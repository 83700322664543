<div class="current-track" *ngIf="player.cued()" [contextMenu]="{item: player.getCuedTrack(), type: 'track'}">
    <media-image [media]="player.getCuedTrack()"></media-image>
    <div class="meta">
        <a class="track-name hover-underline" [routerLink]="urls.track(player.getCuedTrack())">{{player.getCuedTrack().name}}</a>
        <artists-links-list class="artist-name" [artists]="player?.getCuedTrack()?.artists"></artists-links-list>
    </div>
    <div class="toggle-track">
        <library-track-toggle-button [track]="player?.getCuedTrack()"></library-track-toggle-button>
    </div>
</div>

<button class="player-action shuffle-button" mat-icon-button [class.active]="player.getState().shuffling" (click)="player.toggleShuffle()" [matTooltip]="'Shuffle' | trans">
    <mat-icon svgIcon="shuffle"></mat-icon>
</button>

<button class="player-action ondemand-video" mat-icon-button (click)="overlay.maximize()" *ngIf="!shouldHideVideoButton" [disabled]="!player.cued()" [matTooltip]="'Toggle video' | trans">
    <mat-icon svgIcon="ondemand-video"></mat-icon>
</button>
<main-playback-buttons></main-playback-buttons>
<player-seekbar></player-seekbar>
<button class="lyrics" mat-icon-button (click)="showLyricsModal()" [disabled]="!player.cued()" *ngIf="!shouldHideLyricsButton" trans>Lyrics</button>

<div class="lyrics-button-separator"></div>

<button class="player-action cloud-download" mat-icon-button (click)="downloadCurrentTrack()" *ngIf="!shouldHideDownloadButton" [disabled]="!player.cued() || player.state.activePlaybackStrategy !== 'html5'" [matTooltip]="'Download song' | trans">
    <mat-icon svgIcon="cloud-download"></mat-icon>
</button>


<button class="player-action queue-music" mat-icon-button (click)="queueSidebar.toggle()" [matTooltip]="'Toggle queue' | trans">
    <mat-icon svgIcon="queue-music"></mat-icon>
</button>


<button class="player-action repeat-button" mat-icon-button *ngIf="!player.getState().repeatingOne" (click)="player.toggleRepeatMode()" [class.active]="player.getState().repeating" [matTooltip]="'Repeat' | trans">
    <mat-icon svgIcon="repeat"></mat-icon>
</button>

<button class="player-action repeat-button active" mat-icon-button *ngIf="player.getState().repeatingOne" (click)="player.toggleRepeatMode()" [matTooltip]="'Repeat One' | trans">
    <mat-icon svgIcon="repeat-one"></mat-icon>
</button>

<volume-controls></volume-controls>
