import {Component, ElementRef, NgZone, ViewEncapsulation} from '@angular/core';
import {FormattedDuration} from '../../formatted-duration.service';
import {Player} from '../../player.service';
import { WebPlayerState } from 'src/app/web-player/web-player-state.service';
import { BasePlayerSeekbar } from '../player-seekbar/base-player-seekbar';

@Component({
    selector: 'player-seekbar-mobile',
    templateUrl: './player-seekbar-mobile.component.html',
    styleUrls: ['./player-seekbar-mobile.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PlayerSeekbarMobileComponent extends BasePlayerSeekbar {
    constructor(
        protected el: ElementRef<HTMLElement>,
        protected duration: FormattedDuration,
        protected player: Player,
        protected zone: NgZone,
        public state: WebPlayerState,
    ) {
        super();
    }
}
