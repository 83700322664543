<div class="content">
    <custom-menu position="sidebar-primary" class="sidebar-nav"></custom-menu>

    <a mat-flat-button class="upload-button" color="accent" routerLink="/backstage/upload" *ngIf="currentUser.hasPermission('music.create') && settings.get('player.show_upload_btn')" trans>Upload</a>

    <div class="separator"></div>

    <div class="your-music-container">
        <div class="title" trans>Your Music</div>
        <custom-menu position="sidebar-secondary" class="sidebar-nav"></custom-menu>
    </div>

    <div class="separator"></div>

    <div class="playlists-container">
        <div class="header">
            <div class="name" trans>Playlists</div>
            <button mat-icon-button class="new-playlist-button" (click)="openNewPlaylistModal()" [disabled]="!currentUser.hasPermission('playlists.create')">
                <mat-icon svgIcon="playlist-add"></mat-icon>
            </button>
        </div>
        <div class="playlists sidebar-nav">
            <a class="sidebar-nav-item playlist" *ngFor="let playlist of playlists.get()" [contextMenu]="{item: playlist, type: 'playlist'}" [routerLink]="urls.playlist(playlist)">
                {{playlist.name}}
            </a>
        </div>
    </div>
</div>
