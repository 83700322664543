
<!-- <div class="elapsed-time" #elapsedTimeEl *ngIf="state.isMobile">0:00 </div> -->
<div class="player-line">
  <div class="outer-track" #outerTrack>
    <div class="inner-track"></div>
    <div class="progress-track" #progressTrack></div>
    <div class="progress-handle" #progressHandle></div>
  </div>
</div>
<div class="elapsedt" *ngIf="!state.isMobile">
  <div class="elapsed-time" #elapsedTimeEl>0:00 </div>
  <div class="virline"></div>
  <div class="track-length" #trackLengthEl>0:00</div>
</div>